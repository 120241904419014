<template>
	<v-card max-width="800" class="mx-auto mt-5" rounded="xl">
		<v-card-title>{{ $t('settings.changeEmail') }}</v-card-title>
		<v-card-text>
			<v-form ref="form" v-model="valid">
				<v-text-field
					:value="currentEmail"
					:label="$t('auth.currentEmail')"
					prepend-inner-icon="mdi-account-circle"
					outlined
					readonly
					rounded
					hide-details="auto"
					class="pb-3"
					style="max-width:400px;"
				/>
				<v-text-field
					v-model="email"
					:label="$t('auth.newEmail')"
					:hint="$t('auth.emailHelp')"
					:rules="rules.email"
					persistent-hint
					required
					outlined
					prepend-inner-icon="mdi-account-circle"
					rounded
					hide-details="auto"
					class="pb-3"
					style="max-width:400px;"
				/>
			</v-form>
		</v-card-text>

		<v-card-actions>
			<v-btn color="primary" rounded @click="callChangeEmail()">
				{{ $t('settings.changeEmail') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'SettingsChangeEmailForm',
	props: {
		currentEmail: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			valid: false,
			email: '',
			rules: {
				email: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.indexOf('@') !== 0) || this.$t('rules.emailUsername'),
					(v) => (v && v.includes('@')) || this.$t('rules.emailAtSymbol'),
					(v) => (v && v.includes('.')) || this.$t('rules.emailPeriodSymbol'),
					(v) => (v && v.indexOf('.') <= v.length - 3) || this.$t('rules.emailDomain'),
					(v) => (v && /.+@.+\..+/.test(v)) || this.$t('rules.emailValid'),
					(v) => (v && v.length <= this.emailMaxLength) || this.$t('rules.length', { length: this.emailMaxLength })
				]
			}
		}
	},
	computed: {
		emailMaxLength() {
			return 255
		}
	},
	methods: {
		callChangeEmail() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.changeEmail(this.email)
		},
		...mapActions('settings', ['changeEmail'])
	}
}
</script>
